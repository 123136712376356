.alert {

    &-success {
        color: $color-action-valid;
    }

    &-warning {
        color: $color-action-warning;
    }

    &-error {
        color: $color-action-error;
    }
}