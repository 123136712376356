/* Colors */
$color-01: #ff0bac;
$color-02: #2f12dc;
$color-03: #d50cb6;
$color-04: #ac0ebf;
$color-05: #820fc9;
$color-06: #5911d2;
$color-07: #f8fbfe;
$color-08: #ececec;
$color-09: #d7d7d7;
$color-10: #bdbdbd;
$color-11: #424242;
$color-12: #262626;
$color-13: #f0f5f9;
$color-14: #657795;
$color-15: #7a6eaa;
$color-16: #7645d9;
$color-17: #280d5f;

/*
----------------------------------------------------------------------------
Default colors
----------------------------------------------------------------------------
*/
$color-default-text: #111012; /* Default text color */
/*
----------------------------------------------------------------------------
Brand colors
----------------------------------------------------------------------------
*/
$color-brand-blue-picton: #40DBF2;
$color-brand-blue-Anakiwa: #79EDFF;
$color-brand-blue-mint-tulip: #C8E1F5;

$color-brand-purple-purple-heart: #892CD8;
$color-brand-purple-portage: #9E9FF6;
$color-brand-purple-heliotrope: #C574F4;
$color-brand-purple-melrose: #C3A9FF;

$color-brand-rose-mauve: #E9A6FF;
$color-brand-rose-lavender-rose: #F9A9E3;
$color-brand-rose-hot-pink: #FF62A1;
$color-brand-rose-pale-rose: #FFDFED;
/*
----------------------------------------------------------------------------
Action colors
----------------------------------------------------------------------------
*/
$color-action-error: #ff0000;
$color-action-valid: #00ff00;
$color-action-warning: #fca300;

/* Fonts */
$font-family-headings: Righteous, Arial, sans-serif;
$font-family-base: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;

/* Button variables */
$button-active-color: #fafafa;
$button-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15);
$button-shadow-hover: -2px 2px 4px rgba(0, 0, 0, 0.3);

/* Screen size definitions */
$mobile: 320px;
$tablet: 480px;
$desktop: 769px;
$desktop-large: 1024px;

/* Spacings */
$spacing-1x: 8px;
$spacing-2x: 16px;
$spacing-3x: 24px;
$spacing-4x: 32px;
$spacing-5x: 40px;
$spacing-6x: 48px;
$spacing-7x: 56px;
$spacing-8x: 64px;
$spacing-9x: 72px;
$spacing-10x: 80px;
