.btn {
    border: none;
    border-radius: 0;
    padding: 0;
    font-family: $font-family-base;
    font-weight: bold;
    font-size: 14px;
    transition: all 0.5s ease;
    cursor: pointer;
    width: fit-content;

    &-primary {
        background-color: $color-brand-purple-purple-heart;
        color: #fff;
        font-family: $font-family-headings;
        text-transform: uppercase;
        text-align: center;
        border-radius: 8px;
        text-decoration: none;
        padding: 10px 20px;

        &:hover {
            background-color: darken($color-brand-purple-purple-heart, 5);
            box-shadow: $button-shadow-hover;
        }

        &:active {
            background-color: darken($color-brand-purple-purple-heart, 10);
        }
    }

    &-ghost {
        color: #fff;
        border: 2px solid #fff;
        padding: 10px 20px;
        border-radius: 8px;
        font-family: $font-family-headings;
        text-transform: uppercase;

        &:hover {
            color: darken(#fff, 10);
        }

        &:active {
            color: darken(#fff, 20);
        }
    }

    &-pending {
        cursor: progress;

        .icon-spinner {
            margin-right: 10px;
            animation: rotate 1.5s linear infinite;
        }
    }

    &-disabled {
        cursor: not-allowed;
        background-color: $color-08;
        color: $color-10;
    }

    // Helpers
    // Border-radius modules
    &--rounded {
        border-radius: 8px;
    }

    // Font-size modules
    &--fs {
        &-16 {
            font-size: 16px;
        }
        &-13 {
            font-size: 13px;
        }
    }
}
