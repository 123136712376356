.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 21px -10px rgba(110, 110, 110, 0.5);

  &__title {
    font-family: $font-family-headings;
    text-align: center;
    text-transform: uppercase;
  }

  &__img {
    border-radius: 8px;
    margin-bottom: 10px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;

    .icon {
      width: 22px;
      height: 22px;
    }
  }

  &__name {
    font-family: $font-family-headings;
    margin-bottom: 5px;

    &--ellipsis {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }  
}
