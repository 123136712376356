.page-header {
    margin: 40px 15px;
    text-align: center;
    color: #fff;

    @include respond-to(940px) {
        margin-bottom: 125px;
    }

    &__title {
        text-transform: uppercase;
        font-family: $font-family-headings;
        margin-bottom: 5px;
        font-size: 32px;

        @include respond-to(940px) {
            font-size: 48px;
        }
    }
}
