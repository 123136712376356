.card-faucet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 0px 21px -10px rgba(110, 110, 110, 0.5);
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    max-width: 365px;
    width: 100%;

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-family: $font-family-headings;
        font-size: 32px;
    }

    &__subtitle {
        text-align: center;
        font-size: 16px;
        margin-top: 5px;
    }

    .btn {
        margin: 25px auto 0;
    }

    .alert {
        margin-top: 15px;
        text-align: center;
        word-wrap: break-word;
    }
}