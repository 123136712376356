.error-page {
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__title {
        font-size: 64px;
        font-family: $font-family-headings;
        text-transform: uppercase;
        text-align: center;
    }

    &__subtitle {
        font-size: 24px;
        font-family: $font-family-headings;
        text-transform: lowercase;
        text-align: center;
    }
}