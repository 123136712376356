.card.card-unrevealed {
    border-radius: 10px;
    padding: 10px;
    max-width: 350px;
    background-color: rgba(255, 255, 255, .7);
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    .card__name {
      margin-top: auto;
    }

    .btn {
      width: 100%;
      padding: 10px 10px;
      font-size: 16px;
      margin: auto auto 0;
    }

}