.hero {
    color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    text-align: center;
    margin: 0 15px;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &__subtitle {
        font-family: $font-family-headings;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 100;
    }

    &__title {
        text-transform: uppercase;
        font-family: $font-family-headings;
        font-size: 70px;
        margin: 14px 0 20px;

        .highlight {
            color: $color-brand-rose-hot-pink;
        }
    }

    &__description {
        line-height: 1.4;
        font-size: 18px;

        .highlight {
            font-weight: bold;
            color: $color-brand-purple-heliotrope;

            &:hover {
                color: lighten($color-brand-purple-heliotrope, 5);
            }
        }
    }

    &__call-to-actions {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        gap: 10px;
        margin-top: 80px;
        align-items: center;

        .btn {
            min-width: 175px;

            &--wallet-connect {
                padding: 15px 30px;
            }

            &-ghost {
                border: none;
            }
        }
    }

    

    .socials {
        display: flex;
        justify-content: center;
        margin-top: 50px;
        gap: 20px;

        &__item {
            display: flex;
            color: $color-13;
            background-color: $color-14;
            border-radius: 5px;
            padding: 8px;
        }
    }
}