@mixin respond-to($size, $max-size: null) {
  @if ($max-size) {
    @media only screen and (min-width: $size) and (max-width: $max-size) {
      @content;
    }
  } @else {
    @media only screen and (min-width: $size) {
      @content;
    }
  }
}

@mixin gradient-text() {
  color: $color-01;
  background: -webkit-linear-gradient(45deg, $color-01, $color-02);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  display: inline-block;
  background-size: 100%;
}
