.card-basket {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fit-content;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 21px -10px rgba(110, 110, 110, 0.5);
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @include respond-to(940px) {
        flex-direction: row;
        gap: 60px;
        padding: 35px 50px;
    }

    &__section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        &--buy {
            width: auto;
            max-width: 400px;
        }
    }

    &__img {
        width: 100%;
        max-width: 350px;
        border-radius: 8px;
    }

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-family: $font-family-headings;
    }

    .supply-data {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        margin: auto 0;
        padding: 20px 0;

        &__title {
            text-transform: uppercase;
            margin-bottom: 5px;
            font-size: 14px;
            color: $color-10;
        }

        &__value {
            font-family: $font-family-headings;
            font-size: 26px;
        }

        &__img {
            width: 0.9em;
            height: 0.9em;
            margin: 5px 0 0 2px;
        }

        .icon-spinner {
            animation: rotate 1.5s linear infinite;
            color: $color-09;
        }
    }

    .btn {
        width: 100%;
        font-size: 16px;
        padding: 20px 10px;
        margin: auto auto 0;
        text-transform: uppercase;
        font-family: $font-family-headings;
    }

    .alert {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 10px;
    }
}