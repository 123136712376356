.footer {
    margin-top: auto;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;


    .container {
        display: flex;
        justify-content: center;
        gap: 75px;
        margin: 15px auto;
    }

    .navigation {
        &__item {
            font-size: 12px;
            text-transform: uppercase;
            text-decoration: underline;
            margin: 0 10px;

            &:hover {
                color: #fff;
            }
        }
    }

    .socials {
        display: flex;
        gap: 10px;

        &__item {
            &:hover {
                color: #fff;
            }
        }
    }
}
