.header {
    z-index: 2;
    color: rgba(255, 255, 255, 0.7);
    

    &__img-container {
        max-width: 100px;
        height: auto;
        display: flex;
        align-items: center;
    }

    &__img {
        width: 100%;
        height: auto;
    }

    .container {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        height: 60px;
    }

    .navigation {
        display: flex;
        gap: 50px;
        font-family: $font-family-headings;
        margin: 0 25px;
        text-transform: uppercase;
        display: flex;
        align-items: center;

        &__item {
            &:hover {
                color: rgba(255, 255, 255, 0.8);
            }

            &--active {
                color: #fff;
            }
        }
    }

    .btn {
        margin-left: auto;
        display: flex;
        align-self: center;
        width: 180px;
        justify-content: center;
    }

    .icon {
        grid-column: 4;
        margin-left: auto;
        font-size: 25px;
        align-self: center;
        cursor: pointer;
    }
}

.menu-overlay {
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    animation: fadeInAnimation ease-in-out .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    padding: 80px 5px;

    .navigation {
        color: rgba(255, 255, 255, 0.7);
        font-family: $font-family-headings;
        margin: 0 25px;
        text-transform: uppercase;
        font-size: 25px;
        
        &__item {
            border-bottom: 1px solid grey;
            padding: 10px 0;

            &:hover {
                color: rgba(255, 255, 255, 0.8);
            }

            &--active {
                color: #fff;
            }
        }
    }

    .btn {
        display: flex;
        align-self: center;
        justify-content: center;
        margin: 0 auto;
        width: 180px;
        margin-top: auto;
    }
}
