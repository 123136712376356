body {
    background-color: $color-07;
}

#root {
    display: flex;
    flex-direction: column;
    background-color: #55558B;
    background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 0)),
        url('../../../assets/images/backgrounds/titan-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

main {
    height: -moz-calc(100% - 110px); /* Firefox */
    height: -webkit-calc(100% - 110px); /* Chrome, Safari */
    height: calc(100% - 110px);
}

.link {
    color: inherit;
    text-decoration: none;
}

.icon {
    position: relative;
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
}
