@font-face {
    font-family: 'Righteous';
    src: url('../../fonts/righteous/Righteous-Regular.ttf');
}

@font-face {
    font-family: 'Source Sans Pro';
    src: url('../../fonts/source-sans-pro/SourceSansPro-Regular.ttf');
}

body {
    font-family: $font-family-base;
}



