.claim-page {
    width: 100%;
    height: auto;

    &__warning {
        margin: 50px auto;
        text-align: center;
        color: $color-10;
        max-width: 400px;

        &--highlight {
            color: $color-brand-purple-heliotrope;
            font-style: italic;
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        gap: 20px;
    }

    .claim-confirmation {
        height: calc(100vh - 108px);
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        animation: fadeInAnimation ease 3s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        &__title {
            font-size: 64px;
            font-family: $font-family-headings;
            text-transform: uppercase;
            text-align: center;
        }

        &__subtitle {
            font-size: 24px;
            font-family: $font-family-headings;
            text-transform: lowercase;
            text-align: center;
        }

        .btn {
            margin-top: 20px;
        }
    }
}
