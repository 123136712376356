.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;

  &--1300 {
    max-width: 1300px;
  }
}

.content-container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;

  @include respond-to($desktop) {
    margin-top: 5vh;
  }
}
